enifed('ember-glimmer/environment', ['exports', 'ember-babel', 'ember-utils', 'ember-metal', 'ember-debug', 'ember/features', 'ember-views', '@glimmer/runtime', 'ember-glimmer/component-managers/curly', 'ember-glimmer/syntax', 'ember-glimmer/utils/iterable', 'ember-glimmer/utils/references', 'ember-glimmer/utils/debug-stack', 'ember-glimmer/helpers/if-unless', 'ember-glimmer/helpers/action', 'ember-glimmer/helpers/component', 'ember-glimmer/helpers/concat', 'ember-glimmer/helpers/get', 'ember-glimmer/helpers/hash', 'ember-glimmer/helpers/loc', 'ember-glimmer/helpers/log', 'ember-glimmer/helpers/mut', 'ember-glimmer/helpers/readonly', 'ember-glimmer/helpers/unbound', 'ember-glimmer/helpers/-class', 'ember-glimmer/helpers/-input-type', 'ember-glimmer/helpers/query-param', 'ember-glimmer/helpers/each-in', 'ember-glimmer/helpers/-normalize-class', 'ember-glimmer/helpers/-html-safe', 'ember-glimmer/protocol-for-url', 'ember-glimmer/modifiers/action'], function (exports, _emberBabel, _emberUtils, _emberMetal, _emberDebug, _features, _emberViews, _runtime, _curly, _syntax, _iterable, _references, _debugStack, _ifUnless, _action, _component, _concat, _get, _hash, _loc, _log, _mut, _readonly, _unbound, _class, _inputType, _queryParam, _eachIn, _normalizeClass, _htmlSafe, _protocolForUrl, _action2) {
  'use strict';

  function instrumentationPayload(name) {
    return { object: 'component:' + name };
  }

  var Environment = function (_GlimmerEnvironment) {
    (0, _emberBabel.inherits)(Environment, _GlimmerEnvironment);

    Environment.create = function create(options) {
      return new this(options);
    };

    function Environment(_ref) {
      var owner = _ref[_emberUtils.OWNER];
      (0, _emberBabel.classCallCheck)(this, Environment);

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _GlimmerEnvironment.apply(this, arguments));

      _this.owner = owner;
      _this.isInteractive = owner.lookup('-environment:main').isInteractive;

      // can be removed once https://github.com/tildeio/glimmer/pull/305 lands
      _this.destroyedComponents = [];

      (0, _protocolForUrl.default)(_this);

      _this._definitionCache = new _emberMetal.Cache(2000, function (_ref2) {
        var name = _ref2.name,
            source = _ref2.source,
            owner = _ref2.owner;

        var _lookupComponent = (0, _emberViews.lookupComponent)(owner, name, { source: source }),
            componentFactory = _lookupComponent.component,
            layout = _lookupComponent.layout;

        var customManager = undefined;

        if (componentFactory || layout) {
          if (_features.GLIMMER_CUSTOM_COMPONENT_MANAGER) {
            var managerId = layout && layout.meta.managerId;

            if (managerId) {
              customManager = owner.factoryFor('component-manager:' + managerId).class;
            }
          }
          return new _curly.CurlyComponentDefinition(name, componentFactory, layout, undefined, customManager);
        }
      }, function (_ref3) {
        var name = _ref3.name,
            source = _ref3.source,
            owner = _ref3.owner;

        var expandedName = source && _this._resolveLocalLookupName(name, source, owner) || name;

        var ownerGuid = (0, _emberUtils.guidFor)(owner);

        return ownerGuid + '|' + expandedName;
      });

      _this._templateCache = new _emberMetal.Cache(1000, function (_ref4) {
        var Template = _ref4.Template,
            owner = _ref4.owner;

        if (Template.create) {
          var _Template$create;

          // we received a factory
          return Template.create((_Template$create = { env: _this }, _Template$create[_emberUtils.OWNER] = owner, _Template$create));
        } else {
          // we were provided an instance already
          return Template;
        }
      }, function (_ref5) {
        var Template = _ref5.Template,
            owner = _ref5.owner;
        return (0, _emberUtils.guidFor)(owner) + '|' + Template.id;
      });

      _this._compilerCache = new _emberMetal.Cache(10, function (Compiler) {
        return new _emberMetal.Cache(2000, function (template) {
          var compilable = new Compiler(template);
          return (0, _runtime.compileLayout)(compilable, _this);
        }, function (template) {
          var owner = template.meta.owner;
          return (0, _emberUtils.guidFor)(owner) + '|' + template.id;
        });
      }, function (Compiler) {
        return Compiler.id;
      });

      _this.builtInModifiers = {
        action: new _action2.default()
      };

      _this.builtInHelpers = {
        if: _ifUnless.inlineIf,
        action: _action.default,
        concat: _concat.default,
        get: _get.default,
        hash: _hash.default,
        loc: _loc.default,
        log: _log.default,
        mut: _mut.default,
        'query-params': _queryParam.default,
        readonly: _readonly.default,
        unbound: _unbound.default,
        unless: _ifUnless.inlineUnless,
        '-class': _class.default,
        '-each-in': _eachIn.default,
        '-input-type': _inputType.default,
        '-normalize-class': _normalizeClass.default,
        '-html-safe': _htmlSafe.default,
        '-get-dynamic-var': _runtime.getDynamicVar
      };

      if (true) {
        _this.debugStack = new _debugStack.default();
      }
      return _this;
    }

    Environment.prototype._resolveLocalLookupName = function _resolveLocalLookupName(name, source, owner) {
      return _features.EMBER_MODULE_UNIFICATION ? source + ':' + name : owner._resolveLocalLookupName(name, source);
    };

    Environment.prototype.macros = function macros() {
      var macros = _GlimmerEnvironment.prototype.macros.call(this);
      (0, _syntax.populateMacros)(macros.blocks, macros.inlines);
      return macros;
    };

    Environment.prototype.hasComponentDefinition = function hasComponentDefinition() {
      return false;
    };

    Environment.prototype.getComponentDefinition = function getComponentDefinition(name, _ref6) {
      var owner = _ref6.owner,
          moduleName = _ref6.moduleName;

      var finalizer = (0, _emberMetal._instrumentStart)('render.getComponentDefinition', instrumentationPayload, name);
      var source = moduleName && 'template:' + moduleName;
      var definition = this._definitionCache.get({ name: name, source: source, owner: owner });
      finalizer();
      return definition;
    };

    Environment.prototype.getTemplate = function getTemplate(Template, owner) {
      return this._templateCache.get({ Template: Template, owner: owner });
    };

    Environment.prototype.getCompiledBlock = function getCompiledBlock(Compiler, template) {
      var compilerCache = this._compilerCache.get(Compiler);
      return compilerCache.get(template);
    };

    Environment.prototype.hasPartial = function hasPartial(name, _ref7) {
      var owner = _ref7.owner;

      return (0, _emberViews.hasPartial)(name, owner);
    };

    Environment.prototype.lookupPartial = function lookupPartial(name, _ref8) {
      var owner = _ref8.owner;

      var partial = {
        template: (0, _emberViews.lookupPartial)(name, owner)
      };

      if (partial.template) {
        return partial;
      } else {
        throw new Error(name + ' is not a partial');
      }
    };

    Environment.prototype.hasHelper = function hasHelper(name, _ref9) {
      var owner = _ref9.owner,
          moduleName = _ref9.moduleName;

      if (name === 'component' || this.builtInHelpers[name]) {
        return true;
      }

      var options = { source: 'template:' + moduleName };

      return owner.hasRegistration('helper:' + name, options) || owner.hasRegistration('helper:' + name);
    };

    Environment.prototype.lookupHelper = function lookupHelper(name, meta) {
      if (name === 'component') {
        return function (vm, args) {
          return (0, _component.default)(vm, args, meta);
        };
      }

      var owner = meta.owner,
          moduleName = meta.moduleName;

      var helper = this.builtInHelpers[name];

      if (helper) {
        return helper;
      }

      var options = moduleName && { source: 'template:' + moduleName } || {};
      var helperFactory = owner.factoryFor('helper:' + name, options) || owner.factoryFor('helper:' + name);

      // TODO: try to unify this into a consistent protocol to avoid wasteful closure allocations
      if (helperFactory.class.isHelperInstance) {
        return function (vm, args) {
          return _references.SimpleHelperReference.create(helperFactory.class.compute, args.capture());
        };
      } else if (helperFactory.class.isHelperFactory) {
        return function (vm, args) {
          return _references.ClassBasedHelperReference.create(helperFactory, vm, args.capture());
        };
      } else {
        throw new Error(name + ' is not a helper');
      }
    };

    Environment.prototype.hasModifier = function hasModifier(name) {
      return !!this.builtInModifiers[name];
    };

    Environment.prototype.lookupModifier = function lookupModifier(name) {
      var modifier = this.builtInModifiers[name];

      if (modifier) {
        return modifier;
      } else {
        throw new Error(name + ' is not a modifier');
      }
    };

    Environment.prototype.toConditionalReference = function toConditionalReference(reference) {
      return _references.ConditionalReference.create(reference);
    };

    Environment.prototype.iterableFor = function iterableFor(ref, key) {
      return (0, _iterable.default)(ref, key);
    };

    Environment.prototype.scheduleInstallModifier = function scheduleInstallModifier() {
      if (this.isInteractive) {
        var _GlimmerEnvironment$p;

        (_GlimmerEnvironment$p = _GlimmerEnvironment.prototype.scheduleInstallModifier).call.apply(_GlimmerEnvironment$p, [this].concat(Array.prototype.slice.call(arguments)));
      }
    };

    Environment.prototype.scheduleUpdateModifier = function scheduleUpdateModifier() {
      if (this.isInteractive) {
        var _GlimmerEnvironment$p2;

        (_GlimmerEnvironment$p2 = _GlimmerEnvironment.prototype.scheduleUpdateModifier).call.apply(_GlimmerEnvironment$p2, [this].concat(Array.prototype.slice.call(arguments)));
      }
    };

    Environment.prototype.didDestroy = function didDestroy(destroyable) {
      destroyable.destroy();
    };

    Environment.prototype.begin = function begin() {
      this.inTransaction = true;

      _GlimmerEnvironment.prototype.begin.call(this);
    };

    Environment.prototype.commit = function commit() {
      var destroyedComponents = this.destroyedComponents;
      this.destroyedComponents = [];
      // components queued for destruction must be destroyed before firing
      // `didCreate` to prevent errors when removing and adding a component
      // with the same name (would throw an error when added to view registry)
      for (var i = 0; i < destroyedComponents.length; i++) {
        destroyedComponents[i].destroy();
      }

      _GlimmerEnvironment.prototype.commit.call(this);

      this.inTransaction = false;
    };

    return Environment;
  }(_runtime.Environment);

  exports.default = Environment;


  if (true) {
    var StyleAttributeManager = function (_AttributeManager) {
      (0, _emberBabel.inherits)(StyleAttributeManager, _AttributeManager);

      function StyleAttributeManager() {
        (0, _emberBabel.classCallCheck)(this, StyleAttributeManager);
        return (0, _emberBabel.possibleConstructorReturn)(this, _AttributeManager.apply(this, arguments));
      }

      StyleAttributeManager.prototype.setAttribute = function setAttribute(dom, element, value) {
        var _AttributeManager$pro;

        (true && (0, _emberDebug.warn)((0, _emberViews.constructStyleDeprecationMessage)(value), function () {
          if (value === null || value === undefined || (0, _runtime.isSafeString)(value)) {
            return true;
          }
          return false;
        }(), { id: 'ember-htmlbars.style-xss-warning' }));

        (_AttributeManager$pro = _AttributeManager.prototype.setAttribute).call.apply(_AttributeManager$pro, [this].concat(Array.prototype.slice.call(arguments)));
      };

      StyleAttributeManager.prototype.updateAttribute = function updateAttribute(dom, element, value) {
        var _AttributeManager$pro2;

        (true && (0, _emberDebug.warn)((0, _emberViews.constructStyleDeprecationMessage)(value), function () {
          if (value === null || value === undefined || (0, _runtime.isSafeString)(value)) {
            return true;
          }
          return false;
        }(), { id: 'ember-htmlbars.style-xss-warning' }));

        (_AttributeManager$pro2 = _AttributeManager.prototype.updateAttribute).call.apply(_AttributeManager$pro2, [this].concat(Array.prototype.slice.call(arguments)));
      };

      return StyleAttributeManager;
    }(_runtime.AttributeManager);

    var STYLE_ATTRIBUTE_MANANGER = new StyleAttributeManager('style');

    Environment.prototype.attributeFor = function (element, attribute, isTrusting, namespace) {
      if (attribute === 'style' && !isTrusting) {
        return STYLE_ATTRIBUTE_MANANGER;
      }

      return _runtime.Environment.prototype.attributeFor.call(this, element, attribute, isTrusting);
    };
  }
});
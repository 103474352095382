define('ember-elsewhere/services/ember-elsewhere', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      run = Ember.run,
      EmObject = Ember.Object,
      emArray = Ember.A;
  exports.default = Service.extend({
    init: function init() {
      this._super();
      this.set('actives', EmObject.create());
      this._alive = {};
      this._counter = 1;
    },
    show: function show(sourceId, name, component) {
      this._alive[sourceId] = {
        target: name || 'default',
        component: component,
        order: this._counter++
      };
      this._schedule();
    },
    clear: function clear(sourceId) {
      delete this._alive[sourceId];
      this._schedule();
    },
    _schedule: function _schedule() {
      run.scheduleOnce('afterRender', this, this._process);
    },
    _process: function _process() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var newActives = {};
      var alive = this._alive;

      Object.keys(alive).forEach(function (sourceId) {
        var _alive$sourceId = alive[sourceId],
            target = _alive$sourceId.target,
            component = _alive$sourceId.component,
            order = _alive$sourceId.order;

        newActives[target] = newActives[target] || emArray();
        var newActive = component ? { component: component, order: order } : null;

        newActives[target].push(newActive);
      });
      Object.keys(newActives).forEach(function (target) {
        newActives[target] = newActives[target].sortBy('order');
      });

      this.set('actives', EmObject.create(newActives));
    }
  });
});
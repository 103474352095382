define('ember-cli-google-recaptcha/services/g-recaptcha-v3', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  var Promise = Ember.RSVP.Promise;
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var ENV = getOwner(this).resolveRegistration('config:environment');
      this.set('_siteKey', get(ENV, 'ember-cli-google.recaptcha.siteKey'));

      this._instances = {};
    },


    /// The flyweight instances for each siteKey.
    _instances: undefined,

    /// Site key for the application.
    siteKey: readOnly('_siteKey'),

    /**
     * Execute the recaptcha service.
     *
     * @param options         Execute options
     * @param siteKey         Optional site key overriding the default
     * @returns {*}
     */
    execute: function execute(options, siteKey) {
      siteKey = siteKey || this.siteKey;

      return this.getInstance(siteKey).then(function (grecaptcha) {
        return new Promise(function (resolve, reject) {
          grecaptcha.ready(function () {
            grecaptcha.execute(siteKey, options).then(resolve).catch(reject);
          });
        });
      });
    },
    getInstance: function getInstance(siteKey) {
      var promise = this._instances[siteKey];

      if (isPresent(promise)) {
        return promise;
      }

      promise = new Promise(function (resolve, reject) {
        // This is for Fastboot support.
        if (isNone(window) || isNone(window.document)) {
          return resolve();
        }

        window._grecaptcha_onload = function () {
          return resolve(window.grecaptcha);
        };

        // Load the recaptcha script from Google. We do not use jQuery because it is
        // easier and faster to load the script manually by injecting the script tag
        // into the head.
        var script = document.createElement('script');
        script.onerror = function (err) {
          return reject(err);
        };

        //script.defer = true;
        //script.async = true;
        script.src = 'https://www.google.com/recaptcha/api.js?onload=_grecaptcha_onload&render=' + siteKey;

        var head = document.querySelector('head');
        head.appendChild(script);
      });

      this._instances[siteKey] = promise;
      return promise;
    }
  });
});